<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="banks"
      :items-per-page="25"
      class="elevation-3 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              color="purple"
              single-line
              hide-details
              clearable
              @keyup.enter.native="filterData"
              @click:append="filterData"
              @click:clear="filterClear"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
        <v-row class="mt-n16">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </template>

      <template
        v-if="isAllowDetail"
        v-slot:[`item.actions`]="{ item }"
      >
        <v-btn
          small
          dark
          color="purple"
          class="mr-2"
          @click="detailButtonClick(item)"
        >
          DETAIL
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-container>
          <v-card-title class="mb-3">
            Bank Verification
          </v-card-title>
          <v-divider />
          <v-row class="py-3">
            <v-spacer />
          </v-row>
          <div class="px-8">
            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Nama Perusahaan
              </v-col>
              <v-col
                sm="6"
                md="6"
              >
                {{ modalData.name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Nama Direktur
              </v-col>
              <v-col
                sm="6"
                md="6"
              >
                {{ modalData.director_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Nomor Telepon Perusahaan
              </v-col>
              <v-col
                sm="6"
                md="6"
              >
                {{ modalData.phone }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Nama Bank
              </v-col>
              <v-col
                sm="6"
                md="6"
              >
                {{ modalData.bank_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Nomor Rekening
              </v-col>
              <v-col
                sm="6"
                md="6"
              >
                {{ modalData.bank_number }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Nama Pemilik Rekening
              </v-col>
              <v-col
                sm="6"
                md="6"
              >
                {{ modalData.bank_owner }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                sm="6"
                md="6"
              >
                Data History Bank
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          <p style="color:black; font-weight:500;">
                            Nama Bank
                          </p>
                        </th>
                        <th class="text-left">
                          <p style="color:black; font-weight:500;">
                            Nomor Rekening
                          </p>
                        </th>
                        <th class="text-left">
                          <p style="color:black; font-weight:500;">
                            Nama Pemilik
                          </p>
                        </th>
                        <th class="text-left">
                          <p style="color:black; font-weight:500;">
                            Tanggal Perubahan
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in modalData.bank_historical"
                        :key="i"
                      >
                        <td>{{ item.bank_name }}</td>
                        <td>{{ item.bank_number }}</td>
                        <td>{{ item.bank_owner }}</td>
                        <td>{{ item.date }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
          <!-- <v-card-text /> -->
          <v-card-actions class="d-flex justify-center my-5">
            <v-btn
              color="primary"
              @click="verifyButtonClick(1)"
            >
              Accept
            </v-btn>
            <v-btn
              color="red"
              @click="verifyButtonClick(2)"
            >
              Reject
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: '',
      dialog: false,
      headers: [
        { text: 'Nama Perusahaan', align: 'start', value: 'name' },
        { text: 'Nama Direktur', value: 'director_name' },
        { text: 'Telepon', value: 'phone', sortable: false },
        { text: 'Nama Bank', value: 'bank_name', sortable: false },
        { text: 'Nomor Rekening', value: 'bank_number', align: 'center', sortable: false },
        { text: 'Nama Pemilik Rekening', value: 'bank_owner', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      banks: [],
      modalData: {
        name: '',
        director_name: '',
        phone: '',
        bank_name: '',
        bank_number: '',
        bank_owner: '',
      },
      isAllowDetail: true,
      isAllowVerification: false,
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/t/partner/bank/verification', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.banks = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      async verifyButtonClick (status) {
        const requestBody = {
          prt_comp_hash: this.modalData.hash,
          submit_status: status,
        }

        await axios.post('/t/partner/bank/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.dialog = false
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
          this.dialog = false
        })

        this.initialize(1, this.itemsPerPage)
      },

      detailButtonClick (item) {
        this.modalData = item
        this.dialog = true
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 12px !important;
  }
</style>
